import React, { Component } from 'react';
import "../css/home.scss";

export default class Home extends Component
{
    render()
    {
        var items = [
            {
                title: "Lucky Lotto Number Picker",
                description: "You have the power to find your set of lucky lotto numbers. Using our random number generator, we can help you find those numbers. Run through multiple times, use your gut instinct (maybe see the future?), make your picks, and we will analyze your results to give you your top numbers. Only YOU can do it!",
                google_id: "com.lhmgapps.luckylottonumberpicker",
                apple_url: "https://apps.apple.com/us/app/lucky-lotto-number-picker/id1591069274",
                amazon_id: "dp/B092PYPPZK/",
                image_url: "/images/lucky-lotto-number-picker--logo-01-512x512.png",
                website_url: "",
            },
            // {
            //     title: "One Goal A Day",
            //     description: "One change can make a huge difference. Completing just one goal a day can help change your life. Whether you want to start a good habit or kick a bad habit, One Goal A Day can help you. Track your habits, adjust, and become a better you.",
            //     google_id: "",
            //     amazon_id: "",
            //     image_url: "/images/one-goal-a-day--icon-512x512.png",
            //     website_url: "https://www.onegoaladay.com/",
            // }
        ];

        return (
            <div className="container view-home">

                {items.map((item) => (
                    <div key={item.title} className="row" data-game-id={item.google_id}>
                        <div className="col-md-2 d-none d-md-block"></div>
                        <div className="col-6 col-md-4 logo">

                            {item.website_url !== "" &&
                                <a href={item.website_url} target="_blank" ><img alt={item.title} title={item.title} src={item.image_url} /></a>
                            }

                            {item.website_url === "" &&
                                <img alt={item.title} title={item.title} src={item.image_url} />
                            }

                        </div>
                        <div className="col-6 col-md-4 title">

                            {item.website_url !== "" &&
                                <a href={item.website_url} target="_blank" ><h2 alt={item.title} title={item.title}>{item.title}</h2></a>
                            }

                            {item.website_url === "" &&
                                <h2 alt={item.title} title={item.title}>{item.title}</h2>
                            }

                            {item.description &&
                                <div className="description" dangerouslySetInnerHTML={{ __html: item.description }} />
                            }
                            <div className="mobile-stores">
                                {item.google_id &&
                                    <a rel="noopener noreferrer" target="_blank" title={item.title} alt={item.title} href={"https://play.google.com/store/apps/details?id=" + item.google_id}>
                                        <img title={item.title} alt={item.title} src="/images/mobile-stores--android.png" />
                                    </a>
                                }

                                {item.apple_url &&
                                    <a rel="noopener noreferrer" target="_blank" title={item.title} alt={item.title} href={item.apple_url}>
                                        <img title={item.title} alt={item.title} src="/images/mobile-stores--apple.png" />
                                    </a>
                                }

                                {item.amazon_id &&
                                    <a rel="noopener noreferrer" target="_blank" title={item.title} alt={item.title} href={"https://www.amazon.com/" + item.amazon_id}>
                                        <img title={item.title} alt={item.title} src="/images/mobile-stores--amazon.png" />
                                    </a>
                                }
                            </div>
                        </div>
                        <div className="col-md-2 d-none d-md-block"></div>
                    </div>
                ))}

            </div >
        )
    }
}