import React, { Component } from 'react';
import '../css/header.scss';

export default class Header extends Component
{
    state = {
    }
    render()
    {
        return (
            <header>
                <div className="container">
                    <div className="row">
                        <div>
                            <h1><a href="/" title="LHMGApps.com Home">LHMGApps.com</a></h1>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}