import React, { Component } from 'react';
import "../css/footer.scss";

export default class Footer extends Component
{
    state = {
    }
    render()
    {
        return (
            <footer>
                <a href="/">LHMGApps.com ©2021</a>
                <a href="/privacy/">Privacy Policy</a>
            </footer>
        )
    }
}