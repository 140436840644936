import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
//import Feedback from './Views/Feedback';
import Header from './components/Header';
import Footer from './components/Footer';

function App()
{
    return (
        <div className="App">
            <Header />

            <BrowserRouter>
                <div className="content-main">
                    <Switch>
                        <Route exact={true} path='/Privacy' render={() => (
                            <div className="Privacy">
                                <Privacy />
                            </div>
                        )} />                        
                        <Route path='*' render={() => (
                            <div className="Home">
                                <Home />
                            </div>
                        )} />
                    </Switch>
                </div>
            </BrowserRouter>

            <Footer />
        </div>
    );
}

export default App;
